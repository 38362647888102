import { Navigation } from '@components/global/Navigation'
import styled from 'styled-components'
import { device, size } from '@util/responsive'
import { ReactNode, useEffect } from 'react'
import { WarningPanel } from '@components/global/WarningPanel'
import { displayWarning, useWarning } from '@contexts/warnigContext'
import { useVerification } from '@hooks/useVerification'
import { verificationFlowAfterBooking } from '@util/config'
import { useMediaQuery } from '@hooks/mediaQuery'
import { FullScreenLoader } from '@components/global/FullScreenLoader'
import { useCommon } from '@contexts/common'

const MainLayout = ({
    children,
    noWarning,
    menuButtonEnabled = true,
    showBackButton = true,
}: {
    children: ReactNode
    noWarning?: boolean
    menuButtonEnabled?: boolean
    showBackButton?: boolean
}): JSX.Element => {
    const { isUserVerified, hasPendingBooking } = useVerification()
    const { showFullScreeLoader } = useCommon()
    const { warningState, dispatch } = useWarning()
    const isDesktop = useMediaQuery(device.laptop)

    useEffect(() => {
        if (
            typeof isUserVerified !== 'undefined' &&
            !isUserVerified &&
            verificationFlowAfterBooking &&
            !noWarning &&
            isDesktop &&
            hasPendingBooking
        ) {
            dispatch(displayWarning(true))
        } else {
            dispatch(displayWarning(false))
        }
    }, [isUserVerified, hasPendingBooking, noWarning, isDesktop, dispatch])

    return (
        <MainLayoutW>
            {showFullScreeLoader && !isDesktop && <FullScreenLoader />}
            <Navigation menuButtonEnabled={menuButtonEnabled} showBackButton={showBackButton && !isDesktop} />
            <WarningPanel show={warningState.show} />
            <MainContainer noPaddingTop={warningState.show}>{children}</MainContainer>
        </MainLayoutW>
    )
}

const MainLayoutW = styled.div`
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    @media ${device.laptop} {
        background: #f9f9f9;
    }
`

const MainContainer = styled.main<{ noPaddingTop?: boolean }>`
    ${({ noPaddingTop = false }) => (noPaddingTop ? `padding-top: 0` : 'margin-top: 44px')};
    @media (min-width: ${size.laptop}) {
        padding-top: 0;
        min-height: calc(100vh - var(--navHeight));
    }
`

export default MainLayout
