import styled from 'styled-components'

const DropdownCircledBottomSvg = styled.svg<{ isOpen: boolean }>`
    transform: ${(props) => (props.isOpen ? `rotate(180deg)` : ``)};
    transition: transform 0.3s ease 0s;
`

export const DropdownCircledBottom = ({ isOpen }: { isOpen: boolean }) => (
    <DropdownCircledBottomSvg
        isOpen={isOpen}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g filter="url(#filter0_b_2730_206)">
            <rect width="20" height="20" rx="10" fill="#F2F2F7" />
            <path
                d="M9.99221 12.6114C10.1745 12.6062 10.3307 12.5385 10.4714 12.3979L14.4349 8.34578C14.5495 8.22599 14.612 8.08537 14.612 7.91349C14.612 7.56453 14.3412 7.2937 13.9922 7.2937C13.8255 7.2937 13.6693 7.36141 13.5495 7.4812L9.99221 11.127L6.43492 7.4812C6.31512 7.36141 6.16408 7.2937 5.99221 7.2937C5.64325 7.2937 5.37242 7.56453 5.37242 7.91349C5.37242 8.08016 5.43492 8.22599 5.5495 8.34578L9.51825 12.3979C9.65367 12.5437 9.80992 12.6114 9.99221 12.6114Z"
                fill="#3C3C43"
                fill-opacity="0.6"
            />
        </g>
        <defs>
            <filter
                id="filter0_b_2730_206"
                x="-28.995"
                y="-28.995"
                width="77.99"
                height="77.99"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feGaussianBlur in="BackgroundImageFix" stdDeviation="14.4975" />
                <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_2730_206" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_2730_206" result="shape" />
            </filter>
        </defs>
    </DropdownCircledBottomSvg>
)
