import { useAuth } from '@contexts/auth'
import { useFreshChat } from '@hooks/useFreshChat'
import { device, size } from '@util/responsive'
import { useTranslation } from 'next-i18next'
import { FC, forwardRef, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { HamburgerMenu } from './icons/HamburgerMenu'
import { LanguageGlobe } from './icons/LanguageGlobe'
import { Login } from './icons/Login'
import { MiniArrowRight } from './icons/MiniArrowRight'
import LangSwitcher from './LangSwitcher'
import { Cross } from './icons/Cross'
import Link from 'next/link'
import { trackEvent } from '@util/ga'
import { MiniArrowLeft } from './icons/MiniArrowLeft'
import { placeholderAvatarImage } from '@util/config'
import { useSearch } from '@contexts/search'
import { useFeature } from '@hooks/useFeatures'
import { ARABIC_LANG_FOR_ONLY_INTERNAL } from '@util/featureFlags'
import { useLanguage } from '@hooks/useLanguage'
import { LoginDesktop } from './icons/LoginDesktop'
import { useScroll } from '@hooks/useScroll'
import { MeResponse } from '@service/identity.types'
import CitySwitcher from './CitySwitcher'
import MainLogo from './Logo'
import { useLocaleConfig } from '@contexts/config'
import LogoRTL from '@global/LogoRTL'
import { MobileDropDownIconContainer, MobileDropdownItem } from './styled'
import { useRouter } from '@hooks/useRouter'
import { GATrackingActions, GATrackingCategories, Pages } from '@util/enums'
import { useCitySelector } from './useCitySelector'
import { HomenavigationIcon } from './icons/HomeNavigationIcon'
import { MyRentalNavigationIcon } from './icons/MyRentalNavigationIcon'
import { DocumentsNavigationIcon } from './icons/DocumentsNavigationIcon'
import { ProfileNavigationIcon } from './icons/ProfileNavigationIcon'
import { useCommon } from '@contexts/common'
import { LocationNavigationIcon } from './icons/LocationNavigationIcon'
import { SupportNavigationIcon } from './icons/SupportNavigationIcon'
import { useMediaQuery } from '@hooks/mediaQuery'
import { DropdownCircledBottom } from './icons/DropdownCircledBottom'

type NavigationProps = {
    menuButtonEnabled?: boolean
    showBackButton?: boolean
}

export const Navigation: React.FC<NavigationProps> = ({ menuButtonEnabled = true, showBackButton = true }) => {
    const { t } = useTranslation()
    const [isMobileNavigationOpen, setIsMobileNavigationOpen] = useState(false)
    const [isMobileCitySwitcherOpen, setIsMobileCitySwitcherOpen] = useState(false)
    const [isMobileLanguageSwitcherOpen, setIsMobileLanguageSwitcherOpen] = useState(false)
    const { isRTL } = useLocaleConfig()
    const { query, back, replaceWithCity, isPage } = useRouter()
    const { isCareem } = useAuth()
    const { enabled: isArabicLangForInternalEnabled } = useFeature(ARABIC_LANG_FOR_ONLY_INTERNAL)
    const queriedCity = query.city as string
    const { datePickerOverlay, setDatepickerOverlay } = useSearch()
    const mobileNavRef = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (mobileNavRef.current && !mobileNavRef.current.contains(event.target as Node)) {
                setIsMobileNavigationOpen(false)
            }
        }

        if (isMobileNavigationOpen) {
            document.addEventListener('mousedown', handleClickOutside)
        } else {
            document.removeEventListener('mousedown', handleClickOutside)
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [isMobileNavigationOpen])

    const backHandler = () => {
        back()
    }
    const { scrollDirection } = useScroll()
    const { user: userData } = useAuth()

    const isLanguageSwitchDisplayed = isArabicLangForInternalEnabled && !isCareem

    const isMobileSubItemOpened = isMobileLanguageSwitcherOpen || isMobileCitySwitcherOpen

    const getMobileSubItemContent = () => {
        if (isMobileLanguageSwitcherOpen) {
            return <LangSwitcher setIsMobileLanguageSwitcherOpen={setIsMobileLanguageSwitcherOpen} />
        }
        if (isMobileCitySwitcherOpen) {
            return <CitySwitcher setIsSwitcherOpen={setIsMobileCitySwitcherOpen} />
        }
        return null
    }

    const logoClickHandler = () => {
        if (!isPage(Pages.browse)) replaceWithCity('/cars')
    }

    const pageTitles = {
        [Pages.browse]: t('navTitle.home', 'All Cars'),
        [Pages.myRental]: t('navTitle.myRental', 'My Rentals'),
        [Pages.documents]: t('navTitle.Documents', 'Documents'),
        [Pages.profileDetail]: t('navTitle.profile', 'Profile'),
        [Pages.paymentCenter]: t('navTitle.myRental', 'My Rentals'),
    }

    const getNavigationTitle = () => {
        for (const page in pageTitles) {
            if (isPage(page as Pages)) {
                return pageTitles[page as Pages]
            }
        }
        return t('NavTitle', 'All Cars')
    }

    const isDesktop = useMediaQuery(device.laptop)

    const menuButton = () => {
        return (
            <>
                {menuButtonEnabled ? (
                    <MenuCont>
                        {isMobileNavigationOpen ? (
                            <NavIconContainer isCareem={isCareem} isOpen={isMobileNavigationOpen}>
                                {isCareem && <NavigationTitle>{getNavigationTitle()}</NavigationTitle>}
                                <div onClick={() => setIsMobileNavigationOpen(false)}>
                                    {isCareem ? (
                                        <IconCircleBackground>
                                            <DropdownCircledBottom isOpen={isMobileNavigationOpen} />
                                        </IconCircleBackground>
                                    ) : (
                                        <Cross />
                                    )}
                                </div>
                            </NavIconContainer>
                        ) : (
                            <div
                                data-testid="hamburger-menu-open"
                                onClick={() => {
                                    setIsMobileNavigationOpen(true)
                                    trackEvent({
                                        action: GATrackingActions.BURGER_MENU_CLICK,
                                        category: GATrackingCategories.BURGER_MENU,
                                        value: {
                                            userId: userData?.userId || '',
                                            careemId: userData?.careemId || '',
                                            isDesktop: userData,
                                        },
                                    })
                                }}
                            >
                                <NavIconContainer isCareem={isCareem}>
                                    {isCareem && <NavigationTitle>{getNavigationTitle()}</NavigationTitle>}
                                    {isCareem ? (
                                        <IconCircleBackground>
                                            <DropdownCircledBottom isOpen={isMobileNavigationOpen} />
                                        </IconCircleBackground>
                                    ) : (
                                        <HamburgerMenu />
                                    )}
                                </NavIconContainer>
                            </div>
                        )}
                    </MenuCont>
                ) : (
                    <MenuCont />
                )}
            </>
        )
    }

    return (
        <>
            <NavContainer
                className="navbarTop"
                onClick={() => datePickerOverlay && setDatepickerOverlay(false)}
                scrollDirection={scrollDirection}
                isCareem={isCareem}
            >
                <NavHeader>
                    {!isCareem && (
                        <BackButton showBackButton={showBackButton}>
                            {!showBackButton ? null : (
                                <a onClick={backHandler}>
                                    <MiniArrowLeft />
                                </a>
                            )}
                        </BackButton>
                    )}
                    {isCareem && menuButton()}

                    <StyledNav>
                        <LogoWrapper onClick={logoClickHandler} isRTL={isRTL} isCareem={isCareem}>
                            {isRTL ? <LogoRTL /> : <MainLogo height={isDesktop ? 24 : 12} />}
                        </LogoWrapper>
                    </StyledNav>
                    <DesktopNav user={userData} isLanguageSwitchDisplayed={isLanguageSwitchDisplayed} />
                    {!isCareem && menuButton()}
                </NavHeader>

                {isMobileNavigationOpen && (
                    <>
                        {isMobileSubItemOpened ? (
                            getMobileSubItemContent()
                        ) : (
                            <MobileNavigation
                                ref={mobileNavRef}
                                userData={userData}
                                queriedCity={queriedCity}
                                isLanguageSwitchDisplayed={isLanguageSwitchDisplayed}
                                setIsMobileCitySwitcherOpen={setIsMobileCitySwitcherOpen}
                                setIsMobileNavigationOpen={setIsMobileNavigationOpen}
                                setIsMobileLanguageSwitcherOpen={setIsMobileLanguageSwitcherOpen}
                            />
                        )}
                        {!isCareem && <T />}
                    </>
                )}
            </NavContainer>
        </>
    )
}

const ListLink = styled.li`
    cursor: pointer;
`

const T = styled.div`
    left: 0;
    position: fixed;
    top: var(--navHeight);
    width: 100vw;
    height: 100vh;
    display: block;
    opacity: 1;
    z-index: 10;
    background: rgba(0, 0, 0, 0.4);
`

const DesktopExternalLink = styled.a`
    text-decoration: none;
    color: rgba(0, 0, 0, 0.8);
`

type MobileNavigationProps = {
    userData: MeResponse | undefined
    queriedCity: string
    isLanguageSwitchDisplayed: boolean
    setIsMobileCitySwitcherOpen: (isOpen: boolean) => void
    setIsMobileLanguageSwitcherOpen: (isOpen: boolean) => void
    setIsMobileNavigationOpen: (isOpen: boolean) => void
}

const MobileNavigation = forwardRef<HTMLDivElement, MobileNavigationProps>(
    (
        {
            userData,
            isLanguageSwitchDisplayed,
            queriedCity,
            setIsMobileNavigationOpen,
            setIsMobileCitySwitcherOpen,
            setIsMobileLanguageSwitcherOpen,
        },
        ref,
    ) => {
        const { t } = useTranslation()

        const { currentLanguage } = useLanguage()
        const { isCareem } = useAuth()
        const { citySelector } = useCitySelector()
        const { handlerFullScreenLoaderState } = useCommon()
        const isCitySwitcherEnabled = citySelector.enabled && !isCareem

        const { query, asPath, isPage } = useRouter()
        const currentCity = query.city as string
        const { city: cityQuery, ...queryWithoutCity } = query
        const { openFreshChat } = useFreshChat()

        return (
            <MobileDropDown ref={ref} data-testid="hamburger-opened" isCareem={isCareem}>
                {userData && !isCareem && (
                    <>
                        <Link href={`/${queriedCity}/profile`} passHref legacyBehavior>
                            <LoginSection
                                onClick={() => {
                                    trackEvent({
                                        action: 'Profile',
                                        category: 'MenuDR',
                                    })
                                }}
                            >
                                <FlexContainer>
                                    <Avatar>
                                        <ImageContainer src={`/rental/${placeholderAvatarImage}`} alt="" />
                                    </Avatar>
                                    <LoginTextContainer>
                                        <Headline>
                                            {userData.firstName} {userData.lastName}
                                        </Headline>
                                        {isCareem && <FullListText>{userData.email}</FullListText>}
                                    </LoginTextContainer>
                                </FlexContainer>
                                <FlexContainer>
                                    <MiniArrowRight />
                                </FlexContainer>
                            </LoginSection>
                        </Link>
                        <Separator />
                    </>
                )}

                <MobileDropDownContentContainer isCareem={isCareem}>
                    {!userData && (
                        <Link href={`/${queriedCity}/login?redirect=${asPath}`} passHref>
                            <MobileDropDownItemContainer isCareem={isCareem}>
                                <MobileDropdownItem>
                                    <MobileDropDownIconContainer>
                                        <Login />
                                    </MobileDropDownIconContainer>
                                    {isCareem ? (
                                        <MobileMenuCareemText>
                                            {t('menuitem.loginOrSignUp', 'Login or Sign up')}
                                        </MobileMenuCareemText>
                                    ) : (
                                        <MobileDropDownMenuText>
                                            {t('menuitem.loginOrSignUp', 'Login or Sign up')}
                                        </MobileDropDownMenuText>
                                    )}
                                </MobileDropdownItem>
                                {!isCareem && <MiniArrowRight />}
                            </MobileDropDownItemContainer>
                        </Link>
                    )}
                    <Link href={{ pathname: `/${queriedCity}/cars`, query: queryWithoutCity }} passHref>
                        <MobileDropDownItemContainer
                            isCareem={isCareem}
                            onClick={() => {
                                trackEvent({
                                    action: 'Browse',
                                    category: 'MenuDR',
                                })
                                setIsMobileNavigationOpen(false)
                            }}
                            isActive={isCareem ? isPage(Pages.browse) : false}
                            showTopBorder={true}
                        >
                            <MobileDropdownItem>
                                <MobileDropDownIconContainer>
                                    <HomenavigationIcon />
                                </MobileDropDownIconContainer>
                                {isCareem ? (
                                    <MobileMenuCareemText>{t('menuitem.home', 'All Cars')}</MobileMenuCareemText>
                                ) : (
                                    <MobileDropDownMenuText>{t('menuitem.browse', 'See cars')}</MobileDropDownMenuText>
                                )}
                            </MobileDropdownItem>
                            {!isCareem && <MiniArrowRight />}
                        </MobileDropDownItemContainer>
                    </Link>

                    {userData && (
                        <Link href={`/${queriedCity}/profile/myrentals`} passHref>
                            <MobileDropDownItemContainer
                                isCareem={isCareem}
                                isActive={isCareem ? isPage(Pages.myRental) : false}
                            >
                                <MobileDropdownItem
                                    onClick={() => {
                                        trackEvent({
                                            action: GATrackingActions.BURGER_MENU_RENTALS,
                                            category: GATrackingCategories.BURGER_MENU,
                                            value: {
                                                userId: userData?.userId || '',
                                                careemId: userData?.careemId || '',
                                                isDesktop: userData,
                                            },
                                        })

                                        if (isCareem) {
                                            handlerFullScreenLoaderState(true)
                                        }
                                    }}
                                >
                                    <MobileDropDownIconContainer>
                                        <MyRentalNavigationIcon />
                                    </MobileDropDownIconContainer>
                                    {isCareem ? (
                                        <MobileMenuCareemText>
                                            {t('menuitem.myRentals', 'My Rentals')}
                                        </MobileMenuCareemText>
                                    ) : (
                                        <MobileDropDownMenuText>
                                            {t('menuitem.myRentals', 'My rentals')}
                                        </MobileDropDownMenuText>
                                    )}
                                </MobileDropdownItem>
                                {!isCareem && <MiniArrowRight />}
                            </MobileDropDownItemContainer>
                        </Link>
                    )}
                    {userData && isCareem && (
                        <Link href={`/${queriedCity}/profile/documents`} passHref>
                            <MobileDropDownItemContainer
                                isCareem={isCareem}
                                isActive={isCareem ? isPage(Pages.documents) : false}
                            >
                                <MobileDropdownItem
                                    onClick={() => {
                                        if (isCareem) {
                                            handlerFullScreenLoaderState(true)
                                        }
                                    }}
                                >
                                    <MobileDropDownIconContainer>
                                        <DocumentsNavigationIcon />
                                    </MobileDropDownIconContainer>
                                    {
                                        <MobileMenuCareemText>
                                            {t('profileMenuDocuments', 'Documents')}
                                        </MobileMenuCareemText>
                                    }
                                </MobileDropdownItem>
                            </MobileDropDownItemContainer>
                        </Link>
                    )}
                    {userData && isCareem && (
                        <Link href={`/${queriedCity}/profile/details`} passHref>
                            <MobileDropDownItemContainer
                                isCareem={isCareem}
                                isActive={isCareem ? isPage(Pages.profileDetail) : false}
                                showBottomBorder={true}
                            >
                                <MobileDropdownItem
                                    onClick={() => {
                                        if (isCareem) {
                                            handlerFullScreenLoaderState(true)
                                        }
                                    }}
                                >
                                    <MobileDropDownIconContainer>
                                        <ProfileNavigationIcon />
                                    </MobileDropDownIconContainer>
                                    {<MobileMenuCareemText>{t('profileMenuProfile', 'Profile')}</MobileMenuCareemText>}
                                </MobileDropdownItem>
                            </MobileDropDownItemContainer>
                        </Link>
                    )}
                    {!isCareem && (
                        <a style={{ textDecoration: 'none' }}>
                            <MobileDropDownItemContainer
                                isCareem={isCareem}
                                onClick={() => {
                                    openFreshChat()
                                    trackEvent({
                                        action: 'Support',
                                        category: 'MenuDR',
                                    })
                                }}
                            >
                                <MobileDropdownItem>
                                    <MobileDropDownIconContainer>
                                        <SupportNavigationIcon />
                                    </MobileDropDownIconContainer>
                                    <MobileDropDownMenuText>{t('menuitem.support', 'Support')}</MobileDropDownMenuText>
                                </MobileDropdownItem>
                                <MiniArrowRight />
                            </MobileDropDownItemContainer>
                        </a>
                    )}
                    {!isCareem && (
                        <MobileDropDownItemContainer
                            isCareem={isCareem}
                            onClick={() => {
                                isCitySwitcherEnabled && setIsMobileCitySwitcherOpen(true)
                            }}
                        >
                            <MobileDropdownItem>
                                <MobileDropDownIconContainer>
                                    <LocationNavigationIcon />
                                </MobileDropDownIconContainer>
                                <MobileDropDownMenuText>{t(currentCity)}</MobileDropDownMenuText>
                            </MobileDropdownItem>
                            {isCitySwitcherEnabled && <MiniArrowRight />}
                        </MobileDropDownItemContainer>
                    )}
                    {isLanguageSwitchDisplayed && !isCareem && (
                        <MobileDropDownItemContainer
                            isCareem={isCareem}
                            onClick={() => setIsMobileLanguageSwitcherOpen(true)}
                        >
                            <MobileDropdownItem>
                                <MobileDropDownIconContainer>
                                    <LanguageGlobe />
                                </MobileDropDownIconContainer>
                                <MobileDropDownMenuText>
                                    {t('menuItem.language', 'Language')}:{' '}
                                    {t(`headerLanguage${currentLanguage.name}`, currentLanguage.name)}
                                </MobileDropDownMenuText>
                            </MobileDropdownItem>
                            <MiniArrowRight />
                        </MobileDropDownItemContainer>
                    )}
                </MobileDropDownContentContainer>
            </MobileDropDown>
        )
    },
)

MobileNavigation.displayName = 'MobileNavigation'

type DesktopNavProps = {
    user: MeResponse | undefined
    isLanguageSwitchDisplayed: boolean
}

const DesktopNav: FC<DesktopNavProps> = ({ user, isLanguageSwitchDisplayed }) => {
    const { t } = useTranslation()
    const {
        query,
        query: { city },
        asPath,
    } = useRouter()
    const { city: cityQuery, ...queryWithoutCity } = query
    const { openFreshChat } = useFreshChat()
    return (
        <DNav>
            <LeftSide>
                <Link href={{ pathname: `/${city}/cars`, query: queryWithoutCity }} passHref>
                    <ListLink>{t('desktopMenuItem.browse', 'Browse')}</ListLink>
                </Link>
                {user ? (
                    <Link data-testid={'navigation_rentals-link'} href={`/${city}/profile/myrentals`} passHref>
                        <ListLink>{t('desktopMenuItem.rentals', 'Rentals')}</ListLink>
                    </Link>
                ) : null}
                <DesktopExternalLink onClick={() => openFreshChat()}>
                    <ListLink>{t('desktopMenuItem.help', 'Help')}</ListLink>
                </DesktopExternalLink>
            </LeftSide>
            <RightSide>
                <li>
                    <CitySwitcher isDesktop />
                </li>
                {isLanguageSwitchDisplayed && (
                    <li>
                        <LangSwitcher isDesktop />
                    </li>
                )}
                {user ? (
                    <Link data-testid={'navigation_profile-link'} href={`/${city}/profile`} passHref>
                        <ListLink>
                            <RedText>
                                {t('desktopMenuItem.hello', {
                                    firstName: user?.firstName,
                                    defaultValue: 'Hello, {{firstName}}!',
                                })}
                            </RedText>
                        </ListLink>
                    </Link>
                ) : (
                    <Link data-testid={'navigation_login-link'} href={`/${city}/login?redirect=${asPath}`} passHref>
                        <ListLink>
                            <IconAndLink>
                                <LoginDesktop />
                                <RedText>{t('login', 'Login')}</RedText>
                            </IconAndLink>
                        </ListLink>
                    </Link>
                )}
            </RightSide>
        </DNav>
    )
}

const NavigationTitle = styled.p`
    font-size: 12px;
    font-weight: 600;
    line-height: 0px;
    letter-spacing: -0.23999999463558197px;
    text-align: center;
    color: var(--black);
`
const IconCircleBackground = styled.div`
    display: flex;
    align-items: center;
`
const RedText = styled.p`
    color: var(--primaryColor);
`

const Headline = styled.h1`
    font-size: var(--size-24);
    font-weight: var(--weight-extraBold);
    color: black;
    margin: 0;
`

const FullListText = styled.p`
    font-weight: var(--weight-bold);
    font-size: 14px;
    margin: 0;
    margin-top: 4px;
    color: var(--primaryColor);
`

const LoginSection = styled.a`
    padding: 24px;
    display: flex;
    justify-content: space-between;
`

const FlexContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`

const Avatar = styled.div`
    width: 56px;
    height: 56px;
`
const ImageContainer = styled.img`
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 100%;
`
const LoginTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-inline-start: 24px;
    margin-inline-end: 24px;
`
const Separator = styled.div`
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);
    padding: 24px -24px;
`

const DNav = styled.div`
    display: none;
    @media (min-width: ${size.laptop}) {
        display: flex;
        flex: 1;
        align-items: center;
        li {
            list-style: none;
            font-weight: bold;
            margin-inline-start: 2rem;
        }
    }
`

const LeftSide = styled.div`
    display: flex;
    flex: 1;
    list-style: none;
`

const RightSide = styled.div`
    display: flex;
    align-items: center;
    list-style: none;

    & > li {
        position: relative;
    }
`

const LogoWrapper = styled.a<{ isRTL: boolean; isCareem }>`
    margin: ${(props) => (props.isRTL ? '0 0 10px 0' : '3px 0 0 0')};
    height: 24px;
`

const NavContainer = styled.div<{ scrollDirection: 'down' | 'up'; isCareem }>`
    width: 100%;
    display: flex;
    z-index: 100;
    position: fixed;
    background: var(--white);
    height: 44px;
    box-shadow: 0px 20px 20px 1px rgba(0, 0, 0, 0.04);

    @media (min-width: ${size.laptop}) {
        height: var(--navHeight);
        border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);
        box-shadow: none;
        position: sticky;
        top: ${(props) => (props.scrollDirection === 'down' ? '-84px' : '0px')};
        transition-property: all;
        transition-timing-function: cubic-bezier(1, 0.2, 0.2, 1);
        transition-duration: 500ms;
    }
`

const NavIconContainer = styled.div<{ isCareem: boolean; isOpen?: boolean }>`
    display: flex;
    padding: 0.1rem 0.5rem;
    margin-left: -6px;
    ${(props) =>
        props.isCareem
            ? `
    column-gap: 10px;
    align-items: center;
    `
            : `column-gap: 24px`};
    ${(props) =>
        props.isOpen
            ? `
    background: rgba(230, 235, 239, 1);
    border-radius: 1.5rem;
    `
            : ''}
`

const BackButton = styled.div<{ showBackButton: boolean }>`
    min-width: 56px;
    @media (min-width: ${size.laptop}) {
        display: ${(props) => !props.showBackButton && 'none'};
    }
`

const MenuCont = styled.div`
    display: none;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    min-width: 56px;
    @media (min-width: ${size.laptop}) {
        display: none;
    }
`

const StyledNav = styled.nav`
    display: flex;
    align-items: center;
`

const NavHeader = styled.div`
    width: ${size.container};
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: auto;
    padding: 0 var(--padding);
    @media ${device.laptop} {
        padding: 0 var(--desktopPadding);
    }
`

const IconAndLink = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;

    & > p {
        margin-inline-start: 10px;
    }
`

const MobileDropDown = styled.div<{ isCareem }>`
    z-index: 50;
    top: ${({ isCareem }) => (isCareem ? `41px` : 'var(--navMobileHeight)')};
    position: absolute;
    background: white;
    width: ${({ isCareem }) => (isCareem ? `55%` : '100%')};
    border-radius: 16px;
    margin-left: ${({ isCareem }) => (isCareem ? `0.75rem` : '')};
`

const MobileDropDownContentContainer = styled.div<{ isCareem: boolean }>`
    display: flex;
    flex-direction: column;
    padding: var(--padding) var(--padding) 6px;
    ${(props) =>
        props.isCareem &&
        `
    column-gap: 10px;
    border: 1px solid var(--Divider, rgba(230, 230, 230, 1));
    border-radius: 14px;
    padding: 0px;
    box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.16);
    `};
`

const MobileDropDownMenuText = styled.p<{ isOpen?: boolean }>`
    font-weight: var(--weight-semiBold);
    font-size: var(--size-14);
    margin: 0;
    color: ${({ isOpen }) => (isOpen ? `var(--primaryColor)` : 'rgba(0, 0, 0, 0.8)')};
`
const MobileMenuCareemText = styled.p`
    font-family: Manrope;
    font-size: 12px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.23999999463558197px;
    text-align: center;
`

const MobileDropDownItemContainer = styled.div<{
    isCareem: boolean
    isActive?: boolean
    showTopBorder?: boolean
    showBottomBorder?: boolean
}>`
    padding: ${(props) => (props.isCareem ? '0px' : '0 0 var(--padding) 0')};
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: background-color 0.2s ease;
    background-color: ${({ isActive }) => (isActive ? 'rgba(230, 235, 239, 1)' : '')};

    /* Apply top border radius if showTopBorder is true */
    ${({ showTopBorder }) =>
        showTopBorder &&
        `border-top-left-radius: 8px;
    border-top-right-radius: 8px;`}

    /* Apply bottom border radius if showBottomBorder is true */
  ${({ showBottomBorder }) =>
        showBottomBorder &&
        `
    border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;`}

  ${(props) =>
        props.isCareem &&
        `
      &:active {
        background-color: rgba(215, 225, 233, 1);
      }
  `}
`
